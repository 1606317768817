<template>
  <div class="step">
    <div class="title">{{ $t("guide.bindAPI") }}</div>
    <div class="sub">{{ $t("guide.bindAPISub") }}</div>
    <div class="contentBox">
      <el-form
        :model="apiData"
        :rules="rules"
        ref="apiForm"
        class="leftBox formBox"
        :validate-on-rule-change="false"
      >
        <el-form-item prop="key">
          <el-input
            placeholder="API Key"
            v-model="apiData.key"
            autocomplete="off"
            class="inp"
          >
            <div slot="prefix" class="el-input__icon">
              <img src="@/assets/images/guide/key.png" />
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="secret">
          <el-input
            :placeholder="isT2 == 0 ? label2 : $t('account.' + label2)"
            v-model="apiData.secret"
            autocomplete="off"
            class="inp"
          >
            <div slot="prefix" class="el-input__icon">
              <img src="@/assets/images/guide/key.png" />
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="exchange_account_id" v-if="showLabel3 == 1">
          <el-input
            :placeholder="isT3 == 0 ? label3 : $t('account.' + label3)"
            v-model="apiData.exchange_account_id"
            autocomplete="off"
            class="inp"
          >
            <div slot="prefix" class="el-input__icon">
              <img src="@/assets/images/guide/key.png" />
            </div>
          </el-input>
        </el-form-item>
        <div class="g_btn btn" @click="nextStep">{{ $t("common.next") }}</div>
      </el-form>
      <div class="rightBox">
        <div class="imgBox">
          <img src="@/assets/images/guide/contract.png" alt="" />
        </div>
        <div class="apiDetail">
          <p class="apiTitle">{{ $t("guide.bindapi_title", { exchange }) }}</p>
          <p>1.{{ $t("guide.bindapi_1", { exchange }) }}</p>
          <p>2.{{ $t("guide.bindapi_2") }}</p>
          <p>3.{{ $t("guide.bindapi_3") }}</p>
          <p>4.{{ $t("guide.bindapi_4") }}</p>
          <p class="apiSub">{{ $t("guide.ipWhitelist") }}</p>
          <div class="ipBox">
            {{ ipWhitelist }}
            <img
              class="copyBtn"
              @click="handleCopy"
              src="@/assets/images/guide/copy.png"
              alt="copy"
            />
          </div>
          <p>5.{{ $t("guide.bindapi_5") }}</p>
          <p>6.{{ $t("guide.bindapi_6") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Clipboard from "clipboard";
import {accountFormat2,accountFormat3} from "@/utils/methApi/accountFormat.js";
export default {
  data() {
    return {
      // label
      isT2: 0,
      label2: "Api Secret",
      isT3: 0,
      label3: "Passphrase",
      showLabel3: 0,

      exchange: "",

      apiData: {},
    };
  },
  computed: {
    ...mapGetters(['ipWhitelist']),
    rules() {
      return {
        key: {
          required: true,
          message: this.$t("guide.fillIn", { name: "API Key" }),
          trigger: "blur",
        },
        secret: {
          required: true,
          message: this.$t("guide.fillIn", {
            name:
              this.isT2 == 0 ? this.label2 : this.$t("account." + this.label2),
          }),
          trigger: "blur",
        },
        exchange_account_id: {
          required: true,
          message: this.$t("guide.fillIn", {
            name:
              this.isT3 == 0 ? this.label3 : this.$t("account." + this.label3),
          }),
          trigger: "blur",
        },
      };
    },
  },
  methods: {
    // 清空验证提示
    clearValidate(){
      this.$refs.apiForm.clearValidate()
    },
    init(data) {
      this.exchange = data.exchange;
      this.labelTypeData(data.web, data.web_type);
    },
    labelTypeData(web, web_type) {
      var account2 = accountFormat2(web, web_type);
      this.isT2 = account2.isT2;
      this.label2 = account2.label2;
      var account3 = accountFormat3(web, web_type);
      this.isT3 = account3.isT3;
      this.label3 = account3.label3;
      this.showLabel3 = account3.showLabel3;
    },
    nextStep() {
      this.$refs.apiForm.validate((valid) => {
        if (valid) {
          this.$emit("nextStep", {
            ...this.apiData,
            name1:'API Key',
            name2:this.isT2 == 0 ? this.label2 : $t('account.' + this.label2)
          });
        } else {
          return false;
        }
      });
    },

    // 复制
    handleCopy() {
      let clipboard = new Clipboard(".copyBtn", {
        text: () => {
          return this.ipWhitelist;
        },
      });
      clipboard.on("success", () => {
        this.$message({
          message: this.$t("tip.copysuccess"),
          type: "success",
          center: true,
          customClass: "shotMsg shotMsgWhite",
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message({
          message: this.$t("tip.copyerr"),
          type: "error",
          center: true,
          customClass: "shotMsg shotMsgWhite",
        });
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './step.styl';
@import './step2.styl';
</style>